import * as TaskTemplate from 'app/client/core/views/templates/task.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';if (d.tasks && d.tasks.length) {str += '  ';_ds.push(d); var _d1 = d.tasks; for (var _i1 = 0, _len1 = (d.tasks || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;d = _d1[_i1];str += '    ';str += TaskTemplate.render( d);str += '  ';} d = _ds.pop();str += '';} else if (d.readOnly) {str += '  <p class="none-found">No tasks.</p>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
