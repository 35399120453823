import * as FilterSidebarNameTemplate from 'app/client/stories/views/templates/filterSidebarName.html';import FilterModel from 'app/client/core/js/models/filter.js';
import Dialog from 'app/client/core/js/modules/dialog.js';
import Format from 'app/client/core/js/modules/format.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
import FilterController from 'app/client/stories/js/controllers/filter.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Filter'], FilterModel],
  [['Dialog'], Dialog],
  [['Format'], Format],
  [['Tooltip'], Tooltip],
  [['Controller', 'Filter'], FilterController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<button class="action mini" data-on-click="App.Controller.Filter.openDialog">';if (d.filters && d.filters.length > 0) {str += 'Quick Filter';} else {str += 'Add Quick Filters';}str += '</button>';_ds.push(d); var _d0 = d.filters; for (var _i0 = 0, _len0 = (d.filters || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;d = _d0[_i0];str += '  <div class="filter-parent relative">'+'    <div class="list-nav-item filter ';if (d.active) {str += 'active';} else {str += 'inactive';}str += ' ';if (d.inverse) {str += 'inverse';}str += '" data-controller="Filter" data-model="Filter" data-id="';str += _CfS(d.id);str += '" data-on-click="toggle" data-context-menu="contextMenuItems" id="sidebar-filter-';str += _CfS(d.id);str += '" ';if (d.name.length > 16) {str += 'data-tooltip data-tooltip-fn="App.Controller.Filter.filterSidebarTooltip"';}str += '>'+'      ';str += FilterSidebarNameTemplate.render( d);str += '    </div>'+'  </div>';} d = _ds.pop();str += '';if (d.filters && d.filters.length > 0) {str += '  <div class="remove-all-filters">'+'    <a href="#" data-controller="Filter" data-on-click="removeAll" data-type="';str += _CfS(d.type);str += '">'+'      ';str += (App.Controller.Filter.renderCloseIcon());str += '      <span class="remove">Remove ';str += _CfS(App.Format.pluralize(d.filters.length, 'filter', 'filters'));str += '</span>'+'    </a>'+'  </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
