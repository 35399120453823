import * as UserAvatarTemplate from 'app/client/core/views/templates/userAvatar.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="historical-change-v2">'+'  ';if (d.profile) {str += '    ';str += UserAvatarTemplate.render( { profile: d.profile, size: 26, tooltip: true, automation: d.automation });str += '  ';} else {str += '    <span class="unknown-user fa fa-user"></span>'+'  ';}str += '  <div class="value">'+'    <strong>';str += (d.name);str += '</strong>'+'    ';str += (d.html);str += '    <div class="date">';str += _CfS(d.changed_at);str += '</div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
