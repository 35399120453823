
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="referral-binary-reward">'+'    <p class="referral-binary-reward-title">';str += _CfS(d.rewardOne);str += '</p>'+'    <p class="referral-binary-reward-or">Or</p>'+'    <p class="referral-binary-reward-title">';str += _CfS(d.rewardTwo);str += '</p>'+'</div>'+'<p class="referral-binary-reward-description">';str += _CfS(d.description);str += '</p>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
