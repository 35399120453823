
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Ce = Caveman.each;var _CfS = Caveman.forceStr; var str = '';str += '<div class="subnav">'+'  ';_Ce(d.subnav, function (item, _key) {str += '    <div class="subnav-item">'+'      ';if (item.title) {str += '        ';if (item.hr) {str += '<hr/>';}str += '        <h3>';str += (item.name);str += '</h3>'+'      ';} else {str += '        <a data-on-click="';str += _CfS(item.onClick);str += '" class="';if (item.id === d.active) {str += 'active';}str += '">'+'          ';str += (item.name);str += '        </a>'+'        ';if (item.badge) {str += '          <span class="tagged ';str += _CfS(item.badge.color);str += '">';str += _CfS(item.badge.text);str += '</span>'+'        ';}str += '      ';}str += '    </div>'+'  ';});str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
