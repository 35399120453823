
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="attribute editable-attribute story-deadline condensed" data-tabindex data-on-click="openDeadlineDatepicker" data-value="';str += _CfS(d.deadline);str += '">'+'  <span data-testid="attribute-name" class="name">Due</span>'+'  <span class="value">';if (d.formatted_deadline) {str += _CfS(d.formatted_deadline);} else {str += '<em>No date</em>';}str += '</span>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
