import SystemCrashController from 'app/client/core/js/controllers/systemCrash.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'SystemCrash'], SystemCrashController],
  [['Controller', 'SystemCrash'], SystemCrashController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div id="system-crash" data-controller="SystemCrash">'+''+'  <h2>';str += _CfS( d.title || 'Something went wrong.' );str += '</h2>'+''+'  <p>';if (d.description) {str += (d.description);} else {str += 'We weren\'t able to fetch the data required to display this page. We have been notified and are currently investigating. In the meantime, please try refreshing this page, as it may have been a temporary issue.';}str += '</p>'+''+'  <div class="actions">'+'    <button class="action mini light-blue" data-on-click="reloadPage">Reload Page <span class="fa fa-arrow-right"></span></button>'+'  </div>'+''+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
