
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<table class="c3-tooltip">'+'  <tr>'+'    <th colspan="2">'+'      ';str += _CfS(d.title.name || d.title);str += ' ';if (d.title.subtitle) {str += '      <div>';str += _CfS(d.title.subtitle);str += '</div>'+'      ';}str += '    </th>'+'  </tr>'+'  ';if (d.Features) {str += '  <tr class="c3-tooltip-name--Features">'+'    <td class="name"><span style="background-color:';str += _CfS(d.Features.color);str += '"></span>Features</td>'+'    <td class="value">';str += _CfS(d.Features.value);str += '</td>'+'  </tr>'+'  ';}str += '  ';if (d.Bugs) {str += '  <tr class="c3-tooltip-name--Bugs">'+'    <td class="name"><span style="background-color:';str += _CfS(d.Bugs.color);str += '"></span>Bugs</td>'+'    <td class="value">';str += _CfS(d.Bugs.value);str += '</td>'+'  </tr>'+'  ';}str += '  ';if (d.Chores) {str += '  <tr class="c3-tooltip-name--Chores">'+'    <td class="name"><span style="background-color:';str += _CfS(d.Chores.color);str += '"></span>Chores</td>'+'    <td class="value">';str += _CfS(d.Chores.value);str += '</td>'+'  </tr>'+'  ';}str += '  <tr class="c3-tooltip-name--Total-Stories">'+'    <td class="name"><span style="background-color:';str += _CfS(d.total.color);str += '"></span>';str += _CfS(d.total.name);str += '</td>'+'    <td class="value">';str += _CfS(d.total.value);str += '</td>'+'  </tr>'+'  ';if (d.TrailingAverage) {str += '  <tr><td colspan="2"><hr/></td></tr>'+'  <tr class="c3-tooltip-name--Trailing-Average">'+'    <td class="name"><span style="background-color:';str += _CfS(d.TrailingAverage.color);str += '"></span>';str += _CfS(d.TrailingAverage.name);str += '</td>'+'    <td class="value">';str += _CfS(d.TrailingAverage.value);str += '</td>'+'  </tr>'+'  ';}str += '</table>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
