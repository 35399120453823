import AddNewController from 'app/client/core/js/controllers/addNew.js';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory.js';
import EpicModel from 'app/client/core/js/models/epic.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'AddNew'], AddNewController],
  [['Controller', 'AddNewStory'], AddNewStoryController],
  [['Model', 'Epic'], EpicModel],
  [['Controller', 'AddNew'], AddNewController],
  [['Controller', 'AddNewStory'], AddNewStoryController],
  [['Model', 'Epic'], EpicModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';
import _ from 'lodash'


function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var epic = App.Model.Epic.getById(d.epic_id);str += '';str += '';var bulkEditorName = _.get(d.epic, 'raw_name');str += '';if (epic) {str += '  <div class="left-icon">'+'    ';str += (App.Controller.AddNewStory.renderEpicIcon());str += '  </div>'+'  <span data-testid="attribute-name" class="name">Epic</span>'+'  <span class="value">';str += _CfS(epic.name);str += '</span>';} else {str += '  <div class="left-icon">'+'    ';str += (App.Controller.AddNewStory.renderEpicIcon());str += '  </div>'+'  <span data-testid="attribute-name" class="name">Epic</span>'+'  <span class="value"><em>';str += _CfS(bulkEditorName || 'None');str += '</em></span>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
