
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div><button ';if (d.id) {str += 'id="';str += _CfS(d.id);str += '"';}str += ' tabindex="0" data-on-click="';str += _CfS(d.onClick);str += '" class="add-labels action ';str += _CfS(d.size || 'micro');str += ' flat-white"><span class="fa fa-plus"></span> ';str += _CfS(d.text || 'Add Labels...');str += '</button></div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
