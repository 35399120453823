
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="form-help-message">'+'  <span class="help-icon fa fa-info-circle"></span>'+'  The ';str += _CfS(d.name);str += ' integration is currently <em>disabled</em> for <strong>';str += _CfS(d.orgName);str += '</strong>, and can only be <a href="';str += _CfS(d.url);str += '" target="_blank">enabled by an admin <span class="fa fa-external-link-square"></span></a>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
