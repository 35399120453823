import FlashController from 'app/client/core/js/controllers/flash.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Flash'], FlashController],
  [['Controller', 'Flash'], FlashController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="server-message ';str += _CfS(d.className);str += '" ';if (d.id) {str += 'id="';str += _CfS(d.id);str += '"';}str += '>'+'  <span class="fa ';str += _CfS(d.iconLeft);str += ' server-message-icon"></span>'+'  ';if (d.title) {str += '    <h3>';str += (d.title);str += '</h3>'+'  ';}str += '  ';if (d.message) {str += '    <div class="message">';str += (d.message);str += '</div>'+'  ';}str += '  <a href="#" class="close" data-controller="';str += _CfS(d.closeController || 'Flash');str += '" data-on-click="';str += _CfS(d.closeOnClick || 'App.Controller.Flash.close');str += '"><span class="fa fa-times"></span></a>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
