import * as EntityAutoLinkTemplate from 'app/client/core/views/templates/entityAutoLink.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';
import _ from 'lodash'


function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="change-icon change-icon-';if (d.created) {str += 'green';} else {str += 'gray';}str += '">'+'  <span class="fa fa-';if (d.created) {str += 'plus';} else {str += 'trash';}str += '"></span>'+'</div>'+''+'<div class="value">'+'  <strong>';str += (d.name);str += '</strong>'+'  ';if (d.created) {str += 'created';} else {str += 'deleted';}str += '  the Project ';str += EntityAutoLinkTemplate.render( _.assign(d.project, { type: 'project' }));str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
