
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var fieldName = d.fieldName;str += ' ';var epic = d.epic;str += ' ';var value = epic[fieldName];str += ' ';var formattedDate = epic[`formatted_${fieldName}`];str += '';var label = fieldName === 'deadline' ? 'Target Date' : 'Start Date';str += '<div class="attribute-toggle">'+'  <a href="#" data-on-click="clearDate" tabindex="0" data-tooltip="Clear Date"><span class="fa fa-times"></span></a>'+'</div>'+'<div'+'  class="attribute editable-attribute epic-deadline condensed"'+'  tabindex="0"'+'  data-on-click="openDatepicker"'+'  data-value="';str += _CfS(value);str += '"'+'>'+'  <span class="name">';str += _CfS( label );str += '</span>'+'  <span class="value">';if (formattedDate) {str += _CfS( formattedDate );} else {str += '<em>No date</em>';}str += '</span>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
