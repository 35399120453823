import * as InlineLoaderTemplate from 'app/client/core/views/templates/inlineLoader.html';
import * as StoryHasMissingHistoryTemplate from 'app/client/core/views/templates/storyHasMissingHistory.html';import StoryController from 'app/client/core/js/controllers/story.js';
import StoryHistoryController from 'app/client/core/js/controllers/storyHistory.js';
import CommentModel from 'app/client/core/js/models/comment.js';
import StoryModel from 'app/client/core/js/models/story.js';
import StoryHistoryModel from 'app/client/core/js/models/storyHistory.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Story'], StoryController],
  [['Controller', 'StoryHistory'], StoryHistoryController],
  [['Model', 'Comment'], CommentModel],
  [['Model', 'Story'], StoryModel],
  [['Model', 'StoryHistory'], StoryHistoryModel],
  [['Controller', 'Story'], StoryController],
  [['Controller', 'StoryHistory'], StoryHistoryController],
  [['Model', 'Comment'], CommentModel],
  [['Model', 'Story'], StoryModel],
  [['Model', 'StoryHistory'], StoryHistoryModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';

import moment from 'moment'

function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';var last_change_id = null;str += '';var whitelist = App.Model.StoryHistory.getWhiteList();str += '';var activity = App.Controller.StoryHistory.mapActivitytoTemplates(d);str += '';var ascending = App.Model.Comment.commentsAreAscending();str += '';var old_created_at = moment(d.created_at).isBefore('2016-01-06');str += '<div class="activity-focus-dropdown">'+'  Showing: <button data-controller="Story" data-on-click="openActivityFocusDropdown" class="action micro flat-white" id="story-dialog-activity-focus-dropdown">';if (whitelist === 'IMPORTANT') {str += 'Important Changes Only';} else {str += 'Everything';}str += ' <span class="fa fa-caret-down"></span></button>'+'</div>';if (activity.length > 0) {str += '  ';if (!ascending) {str += '    ';activity = activity.reverse();str += '  ';}str += '  <div class="full-story-history">'+'    ';if (old_created_at && ascending) {str += '      ';str += StoryHasMissingHistoryTemplate.render( d);str += '    ';}str += '    ';_ds.push(d); var _d1 = activity; for (var _i1 = 0, _len1 = (activity || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;var change = _d1[_i1];str += '      ';str += (change);str += '    ';} d = _ds.pop();str += '    ';if (old_created_at && !ascending) {str += '      ';str += StoryHasMissingHistoryTemplate.render( d);str += '    ';}str += '  </div>';} else if (d.has_history === false) {str += '  ';str += StoryHasMissingHistoryTemplate.render( d);str += '';} else {str += '  ';str += InlineLoaderTemplate.render( d);str += '';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
