import * as UserAvatarTemplate from 'app/client/core/views/templates/userAvatar.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';var profile = d.profile || d.user;str += '';var name = d.name || (profile && (profile.name || profile.email_address));str += '<div class="person">'+'  ';str += UserAvatarTemplate.render( { profile: profile });str += '  <span data-testid="attribute-name" class="name">';str += _CfS(d.type);str += '</span>'+'  <span class="value';if (d.multiple) {str += ' multiple';}str += '">'+'    ';if (d.disabled) {str += '      <em>';str += _CfS( name );str += '</em>'+'    ';} else {str += '      ';if (name) {str += _CfS(name);} else {str += '<em>Nobody</em>';}str += '    ';}str += '  </span>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
