
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';_ds.push(d); var _d0 = d.items; for (var _i0 = 0, _len0 = (d.items || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var item = _d0[_i0];str += '  ';if (item.hr) {str += '    <div class="hr"></div>'+'  ';} else if (item.html) {str += '    ';str += (item.html);str += '  ';} else {str += '    <div '+'      role="option"'+'      aria-label="';str += _CfS( item.value );str += '"'+'      aria-selected="';str += _CfS( item.value === d.activeValue );str += '"'+'      data-i="';str += _CfS(_i);str += '" '+'      class="item ';if (d.activeValue ? item.value === d.activeValue : _i === 0) {str += 'active';}str += ' ';if (item.disabled) {str += 'disabled';}str += ' ';str += _CfS(item.className);str += '"'+'      ';if (d.applyOnClick && !item.disabled) {str += ' data-on-click="applyValue"';}str += '    >'+'      ';if (item.iconLeft) {str += '        <span class="icon-left fa ';str += _CfS(item.iconLeft);str += '"';if (item.iconLeftColor) {str += ' style="color: ';str += _CfS(item.iconLeftColor);str += '"';}str += '></span>'+'      ';} else if (item.customIconLeft) {str += '        ';str += (item.customIconLeft);str += '      ';}str += '      ';str += (item.name);str += '      ';if (item.note) {str += '        <div class="item-note">';str += _CfS(item.note);str += '</div>'+'      ';}str += '      ';if (item.iconRight) {str += '        <span class="icon-right fa ';str += _CfS(item.iconRight);str += '"></span>'+'      ';}str += '    </div>'+'  ';}str += '';} d = _ds.pop();str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
