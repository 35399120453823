import * as InlineLoaderTemplate from 'app/client/core/views/templates/inlineLoader.html';
import * as SettingsPageHeaderTemplate from 'app/client/settingsShared/views/templates/settingsPageHeader.html';import UploaderController from 'app/client/core/js/controllers/uploader.js';
import Loading from 'app/client/core/js/modules/loading.js';
import EditProfileController from 'app/client/settingsShared/js/controllers/editProfile.js';
import ManageLoadingIconsController from 'app/client/settingsShared/js/controllers/manageLoadingIcons.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Uploader'], UploaderController],
  [['Loading'], Loading],
  [['Controller', 'EditProfile'], EditProfileController],
  [['Controller', 'ManageLoadingIcons'], ManageLoadingIconsController],
  [['Controller', 'EditProfile'], EditProfileController],
  [['Controller', 'ManageLoadingIcons'], ManageLoadingIconsController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div id="organization-settings" class="is-drop-target manage-loading-icons settings-pane" data-icon data-icon-entity="workspace2" data-icon-type="loading" data-aspect-ratio="1" data-uploader-note="For best results, we recommended the image to be at least 300px by 300px.">'+'  '+'  <div class="clearfix">'+'    '+''+'    <div class="right-column" data-controller="ManageLoadingIcons">'+'      ';str += SettingsPageHeaderTemplate.render( { pageName: "Loading Icons"});str += '      <div id="manage-loading-icons">'+''+'        ';if (d.readOnly) {str += '          <div data-component-property-key="readOnlyMessage" data-component-key="read-only-message"></div>'+'          <div class="read-only">'+'        ';}str += '        <div class="blurb">'+'          <p>Upload your own loading icons and have one appear at random on every page load. Note that loading icons are shared by and visible to everyone in the workspace.</p>'+'        </div>'+''+'        <button class="action mini light-gray" data-tabindex data-on-click="openChooser"><span class="align-center">';str += (App.Controller.EditProfile.renderUploadIcon());str += ' Upload Image...</span></button>'+'        <div class="hidden">'+'          <input type="file" name="file" class="html-file-upload" multiple data-controller="Uploader" data-on-change="uploadFiles" data-on-click="" />'+'        </div>'+''+'        <div class="server-messages"></div>'+'        <div class="waiting-for-content">'+'          ';str += InlineLoaderTemplate.render( d);str += '        </div>'+''+'        ';if (d.readOnly) {str += '          </div>'+'        ';}str += '      </div>'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
