import * as EntityAutoLinkTemplate from 'app/client/core/views/templates/entityAutoLink.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';
import _ from 'lodash'


function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="change-icon change-icon-orange">'+'  <span class="fa fa-pencil"></span>'+'</div>'+''+'<div class="value">'+'  <strong>';str += (d.name);str += '</strong>'+'  renamed the Project <em>';str += _CfS(d.oldName);str += '</em> to'+'  ';str += EntityAutoLinkTemplate.render( _.assign(d.project, { type: 'project' }));str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
