import * as EpicAutoLinkTemplate from 'app/client/core/views/templates/epicAutoLink.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';if (d.isDone) {str += '  <div class="value">'+'    The Epic ';str += EpicAutoLinkTemplate.render( d.epic);str += ' has been <strong>completed</strong>!'+'  </div>';} else {str += '  <div class="value">'+'    <strong>';str += (d.name);str += '</strong> moved the Epic ';str += EpicAutoLinkTemplate.render( d.epic);str += '    from ';str += _CfS(d.oldStateName);str += ' to <strong>';str += _CfS(d.newStateName);str += '</strong>'+'  </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
