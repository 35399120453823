import * as EpicAutoLinkTemplate from 'app/client/core/views/templates/epicAutoLink.html';import NotificationsController from 'app/client/core/js/controllers/notifications.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Notifications'], NotificationsController],
  [['Controller', 'Notifications'], NotificationsController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="change-icon change-icon-orange">'+'  <span class="fa fa-pencil"></span>'+'</div>'+''+'<div class="value">'+'  <strong>';str += (d.name);str += '</strong>'+'  ';if (d.status) {str += '  updated the health of ';if (d.entity?.entity_type === 'milestone') {str += (App.Controller.Notifications.renderObjectiveAutoLink(d.entity));} else {str += EpicAutoLinkTemplate.render( d.entity);}str += ' to <strong class="health-text ';str += _CfS(d.status);str += '">';str += _CfS(d.statusLabel);str += '</strong>'+'  ';} else {str += '  mentioned you in a health comment on ';if (d.entity?.entity_type === 'milestone') {str += (App.Controller.Notifications.renderObjectiveAutoLink(d.entity));} else {str += EpicAutoLinkTemplate.render( d.entity);}str += '  ';}str += '</div>';if (d.comment) {str += '  <div class="comment markdown-formatted">'+'    ';str += (d.comment);str += '  </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
