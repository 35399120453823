
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<span class="custom-icon icon-iteration iteration-status-';str += _CfS(d.status || 'unstarted');str += ' ';str += _CfS(d.className);str += '">'+'  ';if (d.status === 'done') {str += '    <svg class="done-symbol" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">'+'      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 27C13.2716 27 16.1763 25.4289 18.0007 23H10C6.68629 23 4 20.3137 4 17C4 13.6863 6.68629 11 10 11C11.7522 11 13.3291 11.7511 14.426 12.949L17.2561 10.1189C15.4338 8.19794 12.8568 7 10 7C4.47715 7 0 11.4772 0 17C0 22.5228 4.47715 27 10 27Z" fill="#0CB350"/>'+'      <path d="M10 23H23V27H10V23Z" fill="#0CB350"/>'+'      <path d="M12.1213 19.1421L23.435 7.82844L26.2634 10.6569L14.9497 21.9706L12.1213 19.1421Z" fill="#0CB350"/>'+'      <path d="M9.29291 16.3137L12.1213 13.4853L14.9498 16.3137L12.1213 19.1421L9.29291 16.3137Z" fill="#0CB350"/>'+'      <path d="M23 30V20L29 25L23 30Z" fill="#0CB350"/>'+'    </svg>'+'  ';} else {str += '    <svg class="started-unstarted-symbol" viewBox="0 0 29 23" version="1.1" xmlns="http://www.w3.org/2000/svg">'+'      <g id="Create,-Edit,-Manage-Iterations" stroke="none" stroke-width="1" fill-rule="evenodd">'+'        <g id="1a-Iterations-Page---First-time-empty" transform="translate(-26.000000, -450.000000)">'+'          <g id="Group" transform="translate(26.000000, 450.000000)">'+'            <path'+'              d="M23,16 L23,13 L29,18 L23,23 L23,20 L10,20 C4.47715,20 0,15.5228 0,10 C0,4.47715 4.47715,0 10,0 C15.5228,0 20,4.47715 20,10 C20,11.4222 19.7031,12.7751 19.1679,14 L14.4722,14 C15.4223,12.9385 16,11.5367 16,10 C16,6.68629 13.3137,4 10,4 C6.68629,4 4,6.68629 4,10 C4,13.3137 6.68629,16 10,16 L18.0007,16 L23,16 Z"'+'              id="Combined-Shape"></path>'+'          </g>'+'        </g>'+'      </g>'+'    </svg>'+'  ';}str += '</span>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
