import CodeBlockController from 'app/client/core/js/controllers/codeBlock.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'CodeBlock'], CodeBlockController],
  [['Controller', 'CodeBlock'], CodeBlockController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="code-block-controls">'+'  <button class="action micro flat-white" id="copy-code-block" data-controller="CodeBlock" data-on-click="copy" data-tooltip="Copy text to clipboard">Copy</button>'+'  <button class="action micro flat-white" id="';if (d.isWordWrapped) {str += 'disable-code-block-wrapping';} else {str += 'enable-code-block-wrapping';}str += '" data-controller="CodeBlock" data-on-click="toggle" data-tooltip="';if (d.isWordWrapped) {str += 'Disable';} else {str += 'Enable';}str += ' code block word wrapping">'+'    ';if (d.isWordWrapped) {str += '<span class="fa fa-check-square-o"></span> Wrap';} else {str += '<span class="fa fa-square-o"></span> Wrap';}str += '  </button>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
