
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<p class="referral-status">'+'  Referrals Pending: ';str += _CfS(d.referrals.pending);str += ' <span id="referral-status-divider"> |&nbsp;</span>'+'</p>'+'<p class="referral-status">'+'  Referrals Achieved: ';str += _CfS(d.referrals.achieved);str += '</p>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
