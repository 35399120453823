import * as EpicListItemTemplate from 'app/client/stories/views/templates/epicListItem.html';
import * as ToggleMoreEpicsTemplate from 'app/client/stories/views/templates/toggleMoreEpics.html';import Globals from 'app/client/core/js/_frontloader/globals.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Globals'], Globals]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="epic-nav-items unselectable">'+'  <div class="epic-parent relative">'+'    <div class="epic-nav-item list-nav-item ';if (d.displayStoriesNotInEpics) {str += 'active';}str += '" id="stories-not-in-epics" data-on-click="toggleOrphanVisibility" data-context-menu="noEpicContextMenuItems">'+'      <span class="fa ';if (d.displayStoriesNotInEpics) {str += 'fa-check-square';} else {str += 'fa-square-o';}str += '"></span>'+'      <span class="name">No Epic</span>'+'    </div>'+'  </div>'+'  ';_ds.push(d); var _d0 = d.epics; for (var _i0 = 0, _len0 = (d.epics || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;d = _d0[_i0];str += '    ';str += EpicListItemTemplate.render( d);str += '  ';} d = _ds.pop();str += '  ';if (d.moreEpics.length > 0) {str += '    <a href="#" class="toggle-more toggle-more-epics" data-on-click="toggleMoreEpics">';str += ToggleMoreEpicsTemplate.render( d);str += '</a>'+'    <div class="more-epics ';if (!App.Globals.get('showMoreEpics')) {str += 'hidden';}str += '">'+'      ';_ds.push(d); var _d1 = d.moreEpics; for (var _i1 = 0, _len1 = (d.moreEpics || []).length; _i1 < _len1; _i1++) { _i = _i1; _len = _len1;d = _d1[_i1];str += '        ';str += EpicListItemTemplate.render( d);str += '      ';} d = _ds.pop();str += '    </div>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
