import NotificationsController from 'app/client/core/js/controllers/notifications.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Notifications'], NotificationsController],
  [['Controller', 'Notifications'], NotificationsController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var hasAdded = d.checks.hasAdded && !d.checks.hasRemoved;str += '<div class="change-icon change-icon-';if (hasAdded) {str += 'green';} else {str += 'orange';}str += '">'+'  ';str += (App.Controller.Notifications.renderFieldsIcon(d));str += '</div>'+''+'<div class="value">'+'  ';if (d.name) {str += '    <strong>';str += (d.name);str += '</strong>'+'  ';}str += '  ';str += (d.body);str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
