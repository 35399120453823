import Dialog from 'app/client/core/js/modules/dialog.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Dialog'], Dialog],
  [['Dialog'], Dialog]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (App.Dialog.onPrev) {str += '  <a href="#" class="prev modal-nav-item fa fa-chevron-left" data-on-click="App.Dialog.prev" data-tabindex></a>';}str += '';if (App.Dialog.onNext) {str += '  <a href="#" class="next modal-nav-item fa fa-chevron-right" data-on-click="App.Dialog.next" data-tabindex></a>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
