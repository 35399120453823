import * as StoryBadgesTemplate from 'app/client/core/views/templates/storyBadges.html';
import * as StoryClassesTemplate from 'app/client/core/views/templates/storyClasses.html';import EpicsController from 'app/client/core/js/controllers/epics.js';
import StoryController from 'app/client/core/js/controllers/story.js';
import BulkSelectionModel from 'app/client/core/js/models/bulkSelection.js';
import EpicModel from 'app/client/core/js/models/epic.js';
import StoryModel from 'app/client/core/js/models/story.js';
import Format from 'app/client/core/js/modules/format.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Epics'], EpicsController],
  [['Controller', 'Story'], StoryController],
  [['Model', 'BulkSelection'], BulkSelectionModel],
  [['Model', 'Epic'], EpicModel],
  [['Model', 'Story'], StoryModel],
  [['Format'], Format],
  [['Tooltip'], Tooltip],
  [['Controller', 'Epics'], EpicsController],
  [['Controller', 'Story'], StoryController],
  [['Model', 'BulkSelection'], BulkSelectionModel],
  [['Model', 'Epic'], EpicModel],
  [['Model', 'Story'], StoryModel],
  [['Format'], Format],
  [['Tooltip'], Tooltip]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';var isSelected = App.Model.BulkSelection.isSelected(d);str += '';var isDone = App.Model.Story.isDoneState(d);str += '<a href="';str += _CfS(App.Model.Story.generateStoryURL(d));str += '" id="story-';str += _CfS(d.id);str += '" data-updated-at="';str += _CfS(d.updated_at);str += '" data-model="Story" data-id="';str += _CfS(d.id);str += '" class="';str += StoryClassesTemplate.render( d);str += ' story-link ';if (isSelected) {str += 'selected';}str += '" data-controller="';str += _CfS(d.onClickController || 'StoryDialog');str += '" data-on-click="';str += _CfS(d.onClick || 'open');str += '">'+'  <span class="story-summary clearfix">'+''+'    ';if (d.epic) {str += '      <span class="epic-tag" data-model="Epic" data-id="';str += _CfS(d.epic.id);str += '" data-tooltip data-tooltip-fn="App.Controller.Epics.renderEpicTooltip">';str += _CfS(d.epic.name);str += '</span>'+'    ';}str += '    <span class="summary-title js-item-title">'+'      ';str += (App.Model.Story.getFormattedName(d));str += '      ';if (isDone) {str += '<span class="fa fa-check" data-tooltip="This Story is done. 👍"></span>';}str += '      ';if (d.archived) {str += '<span class="fa fa-archive" data-tooltip="This Story is archived."></span>';}str += '    </span>'+''+'    ';str += StoryBadgesTemplate.render( d);str += '    ';if (d.reveal && !d.archived) {str += '      <button class="reveal action mini bright-white" data-tooltip="Reveal in Column" data-controller="StoriesStory" data-on-click="revealStory">Reveal</button>'+'    ';}str += '  </span>'+''+'  <button class="bulk-checkbox ';if (isSelected) {str += 'selected';}str += '" data-model="Story" data-controller="Story" data-id="';str += _CfS(d.id);str += '" data-on-click="toggleSelection" data-tooltip="Edit Multiple Stories" data-tooltip-delay="800"><span class="fa ';if (isSelected) {str += 'fa-check-square';} else {str += 'fa-square-o';}str += '"></span></button>'+'</a>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
