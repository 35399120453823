import * as StoryDetailsTemplate from 'app/client/core/views/templates/storyDetails.html';import StoryModel from 'app/client/core/js/models/story.js';
import Dialog from 'app/client/core/js/modules/dialog.js';
import Url from 'app/client/core/js/modules/url.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Story'], StoryModel],
  [['Dialog'], Dialog],
  [['Url'], Url]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div data-model="Story" data-id="';str += _CfS(d.id);str += '" class="story story-page story-';str += _CfS(d.id);str += '">'+'  <div class="story-story-header">'+'    <a class="back-to-stories" href="';str += _CfS(App.Url.getSlugPath());str += '/stories"><span class="fa fa-arrow-circle-left"></span> Back to Stories</a>'+'    ';str += (App.Dialog.renderPinButton({ globalId: d.global_id }));str += '    <a href="';str += _CfS(App.Url.getSlugPath());str += '/stories" class="close-story fa fa-times" data-tooltip="Return to Stories view"></a>'+'  </div>'+''+'  ';str += StoryDetailsTemplate.render( d);str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
