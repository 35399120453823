
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="change-icon change-icon-blue">'+'  <span class="fa fa-arrow-right"></span>'+'</div>'+''+'<div class="value">'+'  ';if (d.name) {str += '    <strong>';str += (d.name);str += '</strong>'+'  ';}str += '  ';if (d.completed) {str += '    marked ';str += (d.story);str += ' as <em>complete</em> by moving it'+'  ';} else if (d.started) {str += '    marked ';str += (d.story);str += ' as <em>started</em> by moving it'+'  ';} else {str += '    moved ';str += (d.story);str += '  ';}str += '  to'+'  ';if (d.workflowName) {str += '    the <strong>';str += _CfS(d.workflowName);str += '</strong> Workflow with the state <strong>';str += _CfS(d.newWorkflowStateName);str += '</strong>'+'  ';} else if (d.newWorkflowStateName) {str += '    <strong>';str += _CfS(d.newWorkflowStateName);str += '</strong>'+'  ';} else {str += '    <strong>a deleted workflow state</strong>'+'  ';}str += '  ';if (d.owner) {str += '    and added <strong>';str += (d.owner);str += '</strong> as an owner'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
