import HelpController from 'app/client/core/js/controllers/help.js';
import StoryController from 'app/client/core/js/controllers/story.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Help'], HelpController],
  [['Controller', 'Story'], StoryController],
  [['Controller', 'Help'], HelpController],
  [['Controller', 'Story'], StoryController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.archived) {str += '  <div class="story-archived" data-controller="Story">'+'    <div class="archive-actions">'+'      <a href="#" class="action mini white" data-on-click="unarchiveStory">Unarchive</a>'+'      <a href="#" class="action mini red" data-on-click="deleteStory">Delete</a>'+'    </div>'+'    <h3><span class="fa fa-archive"></span> This story is archived.</h3>'+'    <p>Archived stories don\'t appear on the Stories page and aren\'t included in any metrics, but can be viewed directly using their permalink. We recommend that you only archive Stories you don\'t end up doing. (<a href="';str += _CfS(App.Controller.Help.KNOWLEDGE_BASE_SLUGS.ARCHIVING_FAQ);str += '" target="_blank">Here\'s why.</a>)</p>'+'  </div>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
