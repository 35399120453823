
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var isAdded = d.checks.hasNew && !d.checks.hasOld;str += '<div class="change-icon change-icon-';if (isAdded) {str += 'green';} else {str += 'orange';}str += '">'+'  <span class="fa fa-signal"></span>'+'</div>'+''+'<div class="value">'+'  ';if (d.name) {str += '    <strong>';str += (d.name);str += '</strong>'+'  ';}str += '  ';if (isAdded) {str += '    set the estimate on ';str += (d.story);str += ' to <strong>';str += _CfS(d.estimate.new);str += '</strong>'+'  ';} else if (!d.checks.hasNew && d.checks.hasOld) {str += '    removed the estimate on ';str += (d.story);str += '  ';} else {str += '    ';if (d.checks.hasOld) {str += '      ';if (d.estimate.new > d.estimate.old) {str += 'increased';} else {str += 'decreased';}str += ' the estimate on ';str += (d.story);str += ' from <strong>';str += _CfS(d.estimate.old);str += '</strong>'+'    ';} else {str += '      set the estimate on ';str += (d.story);str += '    ';}str += '    to <strong>';str += _CfS(d.estimate.new);str += '</strong>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
