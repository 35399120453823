import AvatarController from 'app/client/core/js/controllers/avatar.js';
import TooltipController from 'app/client/core/js/controllers/tooltip.js';
import ProfileModel from 'app/client/core/js/models/profile.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';
import User from 'app/client/core/js/modules/user.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Avatar'], AvatarController],
  [['Controller', 'Tooltip'], TooltipController],
  [['Model', 'Profile'], ProfileModel],
  [['Tooltip'], Tooltip],
  [['User'], User],
  [['Controller', 'Avatar'], AvatarController],
  [['Controller', 'Tooltip'], TooltipController],
  [['Model', 'Profile'], ProfileModel],
  [['Tooltip'], Tooltip],
  [['User'], User]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var size = d.size || 24;str += '';var tooltip = d.tooltip;str += '<div class="avatar" style="width: ';str += _CfS(size);str += 'px; height: ';str += _CfS(size);str += 'px;">';if (d.profile) {str += '  ';if (d.profile.display_icon) {str += '    <img'+'      width="100%"'+'      height="100%"'+'      src="';str += _CfS(d.profile.display_icon.url);str += '"'+'      alt="';str += _CfS(d.profile.name);str += '"'+'      ';if (d.onClick) {str += '        data-on-click="';str += _CfS(d.onClick);str += '"'+'        style="cursor: pointer;"'+'      ';}str += '      ';if (tooltip && d.profile.id) {str += '        data-model="Profile"'+'        data-id="';str += _CfS(d.profile.id);str += '"'+'        data-tooltip'+'        data-tooltip-fn="App.Controller.Tooltip.renderUserTooltip"'+'      ';}str += '    />'+'  ';} else if (d.automation) {str += '    ';str += (App.Controller.Avatar.getBrandAvatar());str += '  ';} else {str += '    ';str += (App.Controller.Avatar.getInitialsAvatar({ profile: d.profile, tooltip, size }));str += '  ';}str += '';} else {str += '  ';str += (App.Controller.Avatar.getDefaultAvatar({ size }));str += '';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
