
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="text-diff">'+'  ';if (d.title) {str += '<h2>';str += (d.title);str += '</h2>';}str += '  ';if (d.subtitle) {str += '<h3>';str += _CfS(d.subtitle);str += '</h3>';}str += '  <div class="scrollable">'+'    ';if (d.columns === 2) {str += '      <div class="clearfix">'+'        <div class="diff-left"><div class="diff">';str += (d.diff);str += '</div></div>'+'        <div class="diff-right"><div class="diff">';str += (d.diff);str += '</div></div>'+'      </div>'+'    ';} else {str += '      <div class="diff">';str += (d.diff);str += '</div>'+'    ';}str += '  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
