import * as IterationAutoLinkTemplate from 'app/client/core/views/templates/iterationAutoLink.html';
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="value">'+'  ';if (d.name) {str += '    <strong>';str += (d.name);str += '</strong>'+'  ';}str += '  ';if ((d.new.name && d.old.name)) {str += '    moved ';str += (d.story);str += ' to the Iteration ';str += IterationAutoLinkTemplate.render( d.new);str += ' from the Iteration ';str += IterationAutoLinkTemplate.render( d.old);str += '  ';} else if (d.new.name) {str += '    added ';str += (d.story);str += ' to the Iteration ';str += IterationAutoLinkTemplate.render( d.new);str += '  ';} else {str += '    removed ';str += (d.story);str += ' from the Iteration ';str += IterationAutoLinkTemplate.render( d.old);str += '  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
