import * as InlineLoaderTemplate from 'app/client/core/views/templates/inlineLoader.html';
import * as SettingsPageHeaderTemplate from 'app/client/settingsShared/views/templates/settingsPageHeader.html';import ManageTokensController from 'app/client/settingsShared/js/controllers/manageTokens.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'ManageTokens'], ManageTokensController],
  [['Controller', 'ManageTokens'], ManageTokensController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div id="account-settings-form" class="settings-pane">'+''+'  <div class="clearfix">'+'    '+''+'    <div class="right-column">'+'      ';str += SettingsPageHeaderTemplate.render( { pageName: "API Tokens"});str += '      <div class="form" data-controller="ManageTokens" id="manage-tokens">'+''+'        <div class="form-help-message">'+'          <span class="help-icon fa fa-key"></span>'+'          <span>API tokens can be used to interact with the <a href="';str += _CfS(BRAND.DEVELOPER_WEBSITE_LATEST_URL);str += '" target="_blank">';str += _CfS( BRAND.NAME );str += ' REST API</a>. Note that when you generate a new token, the value will only be displayed once, so be sure to write it down.</span>'+'        </div>'+''+'        <div class="server-messages"></div>'+''+'        <div class="existing-token-container">'+'          ';str += InlineLoaderTemplate.render( d);str += '        </div>'+''+'      </div>'+'    </div>'+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
