import * as AuthOrgSelectorTemplate from 'app/client/appauth/views/templates/authOrgSelector.html';
import * as InlineUserMentionTemplate from 'app/client/core/views/templates/inlineUserMention.html';import ApplicationModel from 'app/client/core/js/models/application.js';
import AppauthPageController from 'app/client/appauth/js/controllers/appauthPage.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Model', 'Application'], ApplicationModel],
  [['Controller', 'AppauthPage'], AppauthPageController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="application" data-controller="AppauthPage" data-model="Application" data-id="';str += _CfS(d.application.id);str += '">'+'  <div class="logos">'+'    <img src="';str += _CfS(d.application.logo);str += '" alt="';str += _CfS(d.application.name);str += '" />'+'    <span class="fa fa-plug"></span>'+'    <img src="';str += _CfS( d.shortcutLogo );str += '" alt="';str += _CfS( BRAND.NAME );str += '" />'+'  </div>'+'  <p><strong>';str += _CfS(d.application.name);str += '</strong> would like your permission to connect with <strong>';str += _CfS( BRAND.NAME );str += '</strong>.</p>'+''+'  <div class="auth-details">'+'    <p>You are currently logged in as <strong>';str += _CfS(d.profile.name);str += '</strong> (<span class="mention-name">';str += InlineUserMentionTemplate.render( d);str += '</span>).</p>'+'    <h4>';str += _CfS(d.application.name);str += ' will be able to:</h4>'+'    <ul>'+'      <li>Read your Stories</li>'+'      <li>Create and update Stories</li>'+'    </ul>'+'    <h4>';str += _CfS(d.application.name);str += ' will not be able to:</h4>'+'    <ul>'+'      <li>Read your email address</li>'+'      <li>See your ';str += _CfS( BRAND.NAME );str += ' password</li>'+'    </ul>'+'  </div>'+''+'  <div class="server-messages"></div>'+''+'  <div id="org-selector">'+'    ';str += AuthOrgSelectorTemplate.render( d);str += '    <div id="install-action">'+'      <button type="button" class="action green" data-on-click="authorize" id="authorize-button">Authorize</button>'+'    </div>'+'  </div>'+''+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
