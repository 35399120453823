
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';if (d.open) {str += 'opened';} else {str += 'closed';}str += ' the pull request'+' <a href="';str += _CfS(d.pullRequest.url);str += '" target="_blank">#';str += _CfS(d.pullRequest.number);str += ': ';str += _CfS(d.pullRequest.title);str += '</a>';str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
