import AddNewController from 'app/client/core/js/controllers/addNew.js';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory.js';
import OrganizationModel from 'app/client/core/js/models/organization.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'AddNew'], AddNewController],
  [['Controller', 'AddNewStory'], AddNewStoryController],
  [['Model', 'Organization'], OrganizationModel],
  [['Controller', 'AddNew'], AddNewController],
  [['Controller', 'AddNewStory'], AddNewStoryController],
  [['Model', 'Organization'], OrganizationModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="new-story-actions inline-actions story-dropdown-target">'+'  <button class="action flat-white add-new-cancel" data-on-click="cancel" data-tabindex data-tooltip="This will delete your draft." data-context="';str += _CfS(d.context);str += '">'+'    Discard';if (!d.isMobile) {str += ' Draft';}str += '  </button>'+'  <div class="save-options-container">'+'    ';if (d.useCreateAnother) {str += '      ';str += (App.Controller.AddNewStory.renderCreateAnotherCheckbox(d.context, d.useCreateAnother));str += '    ';}str += '    ';if (App.Model.Organization.templatesAreEnabled()) {str += '      <div class="save-options">'+'        <button data-on-click="save" class="action green create-button" data-tabindex data-context="';str += _CfS(d.context);str += '" data-use-create-another="';str += _CfS(d.useCreateAnother);str += '">'+'          Create';if (!d.isMobile) {str += ' Story';}str += '        </button>'+'        <button class="action green story-dropdown-target" data-tabindex data-on-click="openSaveOptionsDropdown">'+'          <span class="fa fa-caret-down"></span>'+'          <span class="visually-hidden">Open save options</span>'+'        </button>'+'      </div>'+'    ';} else {str += '      <button data-on-click="save" class="action green create-button" data-tabindex data-context="';str += _CfS(d.context);str += '" data-use-create-another="';str += _CfS(d.useCreateAnother);str += '">Create Story</button>'+'    ';}str += '  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
