import * as DropdownItemsTemplate from 'app/client/core/views/templates/dropdownItems.html';import DropdownController from 'app/client/core/js/controllers/dropdown.js';
import DropdownModel from 'app/client/core/js/models/dropdown.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Dropdown'], DropdownController],
  [['Model', 'Dropdown'], DropdownModel],
  [['Controller', 'Dropdown'], DropdownController],
  [['Model', 'Dropdown'], DropdownModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div id="dropdown-';str += _CfS(d.id);str += '" class="dropdown ';str += _CfS(d.className);str += '" data-controller="Dropdown" data-model="Dropdown" data-id="';str += _CfS(d.id);str += '">'+'  ';if (d.title) {str += '    <div class="dropdown-title">';str += (d.title);str += '</div>'+'  ';}str += '  ';if (d.title || d.showCloseButton) {str += '    <a href="#" class="close-button fa fa-times" data-on-click="close"></a>'+'  ';}str += '  ';if (d.header) {str += '    <div class="dropdown-header">';str += (d.header);str += '</div>'+'  ';}str += '  <div class="list ';if (d.applyOnClick) {str += 'apply-on-click';}str += '">'+'    ';str += DropdownItemsTemplate.render( d);str += '  </div>'+''+'  ';if (d.footer) {str += '    <div class="dropdown-footer">';str += (d.footer);str += '</div>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
