
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="video-embed-container">'+'  <iframe src="';str += _CfS(d.urlStr);str += '" allowfullscreen referrerpolicy="no-referrer" class="loom-iframe" style="width: 100%; border: 0; aspect-ratio: 16 / 9;"></iframe>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
