import ContextMenuController from 'app/client/core/js/controllers/contextMenu.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'ContextMenu'], ContextMenuController],
  [['Controller', 'ContextMenu'], ContextMenuController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _ds = new Array(5), _i, _len;var _CfS = Caveman.forceStr; var str = '';str += '<div class="context-menu" data-controller="ContextMenu">'+'  <ul>'+'    ';_ds.push(d); var _d0 = d.items; for (var _i0 = 0, _len0 = (d.items || []).length; _i0 < _len0; _i0++) { _i = _i0; _len = _len0;var item = _d0[_i0];str += '      <li>'+'        <a href="';str += _CfS(item.url || '#');str += '" ';if (item.onHover) {str += 'data-on-hover="onHover" data-hover-delay="1"';}str += ' data-on-click="applyValue" class="context-link"';if (item.tooltip) {str += ' data-tooltip="';str += _CfS(item.tooltip);str += '" ';if (item.tooltipShortcut) {str += 'data-tooltip-shortcut="';str += _CfS(item.tooltipShortcut);str += '"';}str += ' data-tooltip-fixed="true"';}str += '>'+'          ';if (item.iconLeft) {str += '<span class="fa ';str += _CfS(item.iconLeft);if (item.name) {str += ' left-icon';}str += '"></span>';}str += '          ';if (item.customIcon) {str += '            <span class="align-center">'+'              <span class="';if (item.name) {str += ' left-icon';}str += '">';str += (item.customIcon);str += '</span>'+'              ';if (item.name) {str += _CfS(item.name);}str += '            </span>'+'          ';} else {str += '            ';if (item.name) {str += _CfS(item.name);}str += '          ';}str += '        </a>'+'      </li>'+'    ';} d = _ds.pop();str += '  </ul>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
