
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="toggle';if (d.small) {str += ' small';}str += '">'+'  <button ';if (d.disabled) {str += 'aria-disabled="true"';}str += '    class="toggle-button ';if (d.isActive) {str += 'active';}str += '" '+'    ';if (!d.disabled) {str += '      data-on-click="';str += _CfS(d.onClick);str += '"'+'    ';} else {str += '      data-tooltip="';str += _CfS(d.tooltip);str += '"'+'    ';}str += ' '+'    data-id="';str += _CfS(d.id);str += '"'+'  >'+'    <span class="toggle-target"></span>'+'    <span class="toggle-gutter"></span>'+'</button>'+''+'  <div class="toggle-response"><span class="fa fa-spin fa-spinner"></span></div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
