
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += 'story story-';str += _CfS(d.id);str += ' story-team-';str += _CfS(d.group_id);str += ' story-type-';str += _CfS(d.story_type);str += ' story-project-';str += _CfS(d.project_id);str += ' ';str += _CfS(d.story_type);str += ' ';if (d.archived) {str += 'is-archived';}str += ' ';if (d.isSearchResult) {str += 'search-result';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
