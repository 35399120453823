import FigmaController from 'app/client/core/js/controllers/figma.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Figma'], FigmaController],
  [['Controller', 'Figma'], FigmaController]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';var fileType = d.isPrototype ? 'Prototype' : 'Design';str += '<div class="figma-container">'+'  <div class="figma-title"><span class="figma-link-type">';str += _CfS(fileType);str += ':</span> <a href="';str += _CfS(d.url);str += '" target="_blank">';str += _CfS(d.title);str += '</a>'+'    ';if (!d.isPrototype) {str += '      <a href="#" class="toggle-preview-link ';if (d.showPreviews) {str += 'hide-figma-previews';} else {str += 'show-figma-previews';}str += ' ';if (d.hideToggle) {str += 'hide-toggle';}str += '" data-controller="Figma" data-on-click="togglePreviews" data-tooltip="';if (d.showPreviews) {str += 'Hide Preview';} else {str += 'Show Preview';}str += '"><span class="fa ';if (d.showPreviews) {str += 'fa-caret-down';} else {str += 'fa-caret-right';}str += '"></span></a>'+'    ';}str += '  </div>'+'  <div class="figma-link"><a href="';str += _CfS(d.url);str += '" target="_blank">';str += _CfS(d.url);str += ' <span class="fa fa-external-link"></span></a></div>'+'  ';if (!d.isPrototype && d.showPreviews) {str += '    <iframe src="';str += _CfS(d.iframeSrc);str += '" allowfullscreen></iframe>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
