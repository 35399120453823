import * as StoryAutoLinkTemplate from 'app/client/core/views/templates/storyAutoLink.html';import StoryController from 'app/client/core/js/controllers/story.js';
import StoryDialogController from 'app/client/core/js/controllers/storyDialog.js';
import StoryModel from 'app/client/core/js/models/story.js';
import Dialog from 'app/client/core/js/modules/dialog.js';
import Format from 'app/client/core/js/modules/format.js';
import Tooltip from 'app/client/core/js/modules/tooltip.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Story'], StoryController],
  [['Controller', 'StoryDialog'], StoryDialogController],
  [['Model', 'Story'], StoryModel],
  [['Dialog'], Dialog],
  [['Format'], Format],
  [['Tooltip'], Tooltip],
  [['Controller', 'Story'], StoryController],
  [['Controller', 'StoryDialog'], StoryDialogController],
  [['Model', 'Story'], StoryModel],
  [['Dialog'], Dialog],
  [['Format'], Format],
  [['Tooltip'], Tooltip]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';
import _ from 'lodash'


function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';if (App.Model.Story.isValidForAutoLink(d)) {str += '  ';App.Model.Story.normalize(d);str += '  <a href="';str += _CfS(App.Model.Story.generateStoryURL(d));str += _CfS( d.commentId ? `#activity-${d.commentId}` : '' );str += '" class="entity-title-link" data-model="Story" data-tooltip data-tooltip-fn="App.Controller.Story.renderStoryTooltip" data-on-click="App.Controller.StoryDialog.open" data-id="';str += _CfS(d.id);str += '">'+'    ';str += (App.Model.Story.getFormattedName(d));str += '  </a>'+'  ';if (d.archived) {str += '<span class="story-is-archived">(archived)</span>';}str += '  ';str += StoryAutoLinkTemplate.render( d);str += '';} else if (d && !_.isPlainObject(d)) {str += '  <span class="auto-story-link story-was-deleted" data-tooltip="Story ';str += _CfS(d);str += ' has been deleted.">';str += _CfS(d);str += '</span>';} else {str += '  <em>Unknown Story</em>';}str += '';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
